<template>
  <transportationInvoiceForm />
</template>

<script>

import transportationInvoiceForm from './transportationInvoiceForm.vue';
export default {
  components: {transportationInvoiceForm},
}
</script>

<style>

</style>